export const getMatchTime = (date) => {
  let dateString = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  if (dateString[0] === '0') {
    dateString = dateString.substring(1);
  }
  return dateString.replace(/\s+/g, '');
};

export function formatDate(date) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dateObj = new Date(date);
  const dayOfWeek = daysOfWeek[dateObj.getDay()];
  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];

  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formattedTime = `${hours}:${minutes}${ampm}`;

  return `${dayOfWeek} ${day} ${month}, ${formattedTime}`;
}
