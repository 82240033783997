<script></script>

<div class="hr" />

<style>
  .hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #d9d9d9;
  }
</style>
