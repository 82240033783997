<script>
  export let fontSize = "1em";
  export let fontColor = "#000";
  export let display = "block";
  export let margin = "0px 0px 8px 0px"
</script>

<span
  style="--display: {display}; --font-size: {fontSize}; --font-color: {fontColor}; --margin: {margin};"
  ><slot /></span
>

<style>
  span {
    font-size: var(--font-size);
    font-family: 'Press Start 2P', cursive;
    color: var(--font-color);
    display: var(--display);
    margin: var(--margin);
    line-height: 1.5;
  }
</style>
