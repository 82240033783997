import { db } from "../firebase/";
import { leagueLeaderboardStore, userStore } from "../stores.js";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  where,
  orderBy,
  getDocs,
  query,
} from "firebase/firestore";

export const updateUserSettings = async (userId, settings) => {
  const userRef = doc(db, "__users_collection__", userId);
  try {
    await updateDoc(userRef, { settings: settings });
    return { success: true };
  } catch (error) {
    console.error("Failed to update user settings:", error);
    return { success: false };
  }
};
