<script>
  import { fly } from "svelte/transition";
  import Lottie from "./Lottie.svelte";
  export let fullLogo = false;
  $: bgWidth = fullLogo ? "300px" : "85px";
</script>

<div id="logo-container" style="--bg-width:{bgWidth}">
  <div class="colour-row left-10" in:fly={{ x: -60, duration: 800 }} />
  <div class="colour-row" in:fly={{ x: -60, duration: 900 }} />
  <div class="colour-row left-10" in:fly={{ x: -60, duration: 700 }} />
  <div class="colour-row left-5" in:fly={{ x: -60, duration: 900 }} />
  <div class="colour-row left-10" in:fly={{ x: -60, duration: 700 }} />
  {#if fullLogo}
    <div class="lottie-logo">
      <Lottie name="ot-logo" width={250} height={50} autoplay={true} />
    </div>
  {:else}
    <img src="/images/android-chrome-72x72.png" alt="logo" />
  {/if}
</div>

<style>
  #logo-container {
    margin-left: -1px;
    width: var(--bg-width);
    height: 50px;
  }
  .colour-row {
    width: 100%;
    height: 10px;
    background-color: #fede47;
  }

  .left-10 {
    position: relative;
    right: 7.5px;
  }

  .left-5 {
    position: relative;
    right: 15px;
  }

  img {
    position: absolute;
    height: 50px;
    top: 0px;
    left: 10px;
  }

  .lottie-logo {
    position: absolute;
    height: 50px;
    top: -14px;
    left: 16px;
  }
</style>
