<script>
  import Flex from "./Flex.svelte";
  import Button from "./Button.svelte";
  import VerticalSpacer from "./VerticalSpacer.svelte";
  let email = "";
  let password = "";
  let username = "";
  import { createNewUser } from "../utils/password-login";
  import { userSetup } from "../firebase";
  import { addToast, userStore } from "../stores";
  import Loading from "./Loading.svelte";
  let loading = false;
  const validate = async () => {
    if (password.length <= 6) {
      addToast({
        message: "your password isn't long enough",
        type: "error",
        dismissible: true,
        timeout: 5000,
      });
      loading = false;
      return;
    }
    if (username === "") {
      addToast({
        message: "please provide a name",
        type: "error",
        dismissible: true,
        timeout: 5000,
      });
      loading = false;
      return;
    }
    loading = true;
    try {
      await createNewUser(email, password);
      const userData = await userSetup({ username, sport: "__sport__" });
      userStore.set(userData.data);
      console.log("user created", userData);
      loading = false;
      addToast({
        message: `hey ${username}... get tipping!`,
        type: "success",
        dismissible: true,
        timeout: 5000,
      });
    } catch (error) {
      console.log("Caught", error.message);
      if (error.message.includes('email-already-in-use')){
        addToast({
          message: "that email is already in use. login or reset your password.",
          type: "error",
          dismissible: true,
          timeout: 5000,
        });
      } else {
        addToast({
          message: "something went wrong. check your email is correct.",
          type: "error",
          dismissible: true,
          timeout: 5000,
        });
      }
      loading = false;
    }
  };
</script>

<Flex direction="column">
  <form on:submit|preventDefault={validate}>
    <div class="nes-field">
      <label for={`username_field`}
        >Your name or whatever... (people will see this)</label
      >
      <input
        bind:value={username}
        type="text"
        maxlength="20"
        id={`email_field`}
        class="nes-input"
        placeholder="Robbo etc."
      />
    </div>
    <VerticalSpacer />
    <div class="nes-field">
      <label for={`email_field`}>Your email</label>
      <input
        bind:value={email}
        type="email"
        id={`email_field`}
        class="nes-input"
        maxlength="60"
        autocomplete="username"
        placeholder="you@something.com"
      />
    </div>
    <VerticalSpacer />
    <div class="nes-field">
      <label for={`password_field`}
        >A password that's longer than 6 letters</label
      >
      <input
        bind:value={password}
        type="password"
        id={`password_field`}
        class="nes-input"
        maxlength="50"
        autocomplete="new-password"
        placeholder="new password"
      />
    </div>
    <VerticalSpacer height="20px" />
    <Button fontSize="medium" primary>create account</Button>
  </form>
  {#if loading} <div class="loading"><Loading {loading} /></div>{/if}
</Flex>

<style>
  form {
    position: relative;
  }
  label {
    font-size: small;
    font-family: "Source Code Pro", monospace;
  }
  input {
    font-family: "Source Code Pro", monospace;
    font-size: 16px;
  }
  .loading {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
</style>
