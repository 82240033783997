<script>
  import { LottiePlayer } from "@lottiefiles/svelte-lottie-player";
  export let name = "odd-tipping-logo";
  export let autoplay = false;
  export let loop = false;
  export let width = "100%";
  export let height = "100%";
</script>

<LottiePlayer
  src={`/images/${name}.json`}
  {autoplay}
  {loop}
  controls={false}
  renderer="svg"
  background="transparent"
  width={width}
  height={height}
  controlsLayout={null}
/>
