<script>
  import { onMount, onDestroy } from 'svelte';
  import { fade } from 'svelte/transition';
  import { getFixtures } from '../utils/fixtures';
  import {
    fixtures,
    userStore,
    currentRound,
    competitionStatus,
  } from '../stores';
  import { getTipsByMatchIDs, subscribe } from '../tipsStore.js';
  import {
    Text,
    Heading,
    LoadingText,
    ThickBorder,
    Flex,
    VerticalSpacer,
    RoundSelector,
    Icon,
  } from '../components/ui';
  import SingleMatch from '../components/SingleMatch.svelte';
  import { Link } from 'svelte-navigator';
  let userTips;
  const urlParams = new URLSearchParams(window.location.search);
  const round = urlParams.get('round');
  // TODO - should validate if it's a valid round...
  let selectedRound = parseInt(round || $currentRound);
  // let currentRoundElement;
  // let roundsContainerElement;

  subscribe((value) => {
    userTips = value;
  });

  let userTipsUnsubscribe = [];
  onMount(async () => {
    // Is it useful to have selectedRound stored? Can't reproduce that weird loading bug...
    // if there's a selectedRound, that should override the currentRound...
    // so we don't ever "getCurrentRound" if there is a selectedRound
    await getFixtures(selectedRound);
    const unsub = getTipsByMatchIDs(
      $userStore.id,
      $fixtures[selectedRound].map((match) => match.doc_id)
    );
    userTipsUnsubscribe.push(unsub);
    // const scrollOffset =
    //   currentRoundElement.offsetLeft - roundsContainerElement.offsetLeft;
    // // TODO - might need to adjust this offset slightly to get it in the center
    // roundsContainerElement.scrollLeft = scrollOffset;
  });

  onDestroy(() => {
    userTipsUnsubscribe.forEach((unsub) => unsub());
  });
</script>

{#if $competitionStatus === 'complete'}
<VerticalSpacer height="10px" />
  <ThickBorder padding="10px" dropShadow={null} borderColor="grey" margin="10px">
    <Heading>Thanks for a great season, legends!</Heading>
    <Text margin="0px 0px 4px 0px"
      >Odd Tipping __sport__ will be returning again soon.</Text
    >
    <Text
      >Keep an eye on your email inbox – we'll let you know when it's back on!</Text
    >
  </ThickBorder>
{/if}

{#if $competitionStatus === 'upcoming'}
<VerticalSpacer height="10px" />
  <ThickBorder dropShadow={null} borderColor="yellow" padding="10px" margin="10px">
    <Text fontWeight="600">F*** yeah, footy's back!</Text>
    <Text fontSize="13px" margin="4px 0px 4px 0px"
      >Bloody great you're playing Odd Tipping, ya legend.</Text
    >
    <Text fontSize="13px"
      >If you're not sure what the hell this game is, check out our <Link to="/rules">rules page</Link> – then tell your mates and invite them to play.</Text
    >
  </ThickBorder>
{/if}
<Flex direction="column" padding="0px 0px 0px 10px">
  <VerticalSpacer height="20px" />
  <Flex justify="flex-start">
    <Icon margin="0px 10px 0px 0px" name="calendar" />
    <Heading>matches</Heading>
  </Flex>
</Flex>
<RoundSelector
  {selectedRound}
  onRoundSelect={async (round) => {
    selectedRound = round;
    await getFixtures(Number(selectedRound));
    const unsub = getTipsByMatchIDs(
      $userStore.id,
      $fixtures[selectedRound].map((match) => match.doc_id)
    );
    userTipsUnsubscribe.push(unsub);
  }}
/>
<!-- <div id="rounds-container" bind:this={roundsContainerElement}>
  {#each $rounds as round}
    {#if round === selectedRound}
      <div bind:this={currentRoundElement}>
        <ThickBorder borderColor="yellow" margin="2px 6px 6px 6px">
          <Box display="flex" width="30px" height="30px" margin="4px">
            <Text fontWeight="600">{round}</Text>
          </Box>
        </ThickBorder>
      </div>
    {:else}
      <ThickBorder dropShadow="md" margin="2px 6px 6px 6px">
        <div
          on:click={async () => {
            selectedRound = round;
            await getFixtures(Number(selectedRound));
            const unsub = getTipsByMatchIDs(
              $userStore.id,
              $fixtures[selectedRound].map((match) => match.doc_id)
            );
            userTipsUnsubscribe.push(unsub);
          }}
        >
          <Box display="flex" width="30px" height="30px" margin="4px">
            <Text>{round}</Text>
          </Box>
        </div>
      </ThickBorder>
    {/if}
  {/each}
</div> -->
<Flex direction="column" align="flex-start" padding="0px 10px">
  <VerticalSpacer height="10px" />
  {#if $fixtures && $fixtures[selectedRound] && userTips}
    <div class="matches-container">
      {#each $fixtures[selectedRound] as singleMatchData, i}
        <div in:fade={{ delay: i * 50, duration: 250 }}>
          <SingleMatch
            docId={singleMatchData.doc_id}
            matchData={{ ...singleMatchData }}
            {userTips}
          />
          <VerticalSpacer />
        </div>
      {/each}
    </div>
  {:else}
    <LoadingText />
  {/if}
</Flex>

<!-- <style>
  #rounds-container {
    padding: 10px 0px 10px 0px;
    display: flex;
    direction: row;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    -webkit-mask: linear-gradient(90deg, #0000, #000 2% 98%, #0000);
  }

  #rounds-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
</style> -->
