<script>
  import { onMount, onDestroy } from 'svelte';
  import { fade } from 'svelte/transition';
  import { Link } from 'svelte-navigator';
  import {
    Text,
    Heading,
    Flex,
    Icon,
    VerticalSpacer,
    PageContainer,
    RoundSelector,
    DataCard,
    LoadingText,
    ThickBorder,
    Form,
  } from '../components/ui';
  import SingleMatch from '../components/SingleMatch.svelte';
  import { getFixtures } from '../utils/fixtures';
  import { fixtures, currentRound, userStore, badges } from '../stores';
  import { getTipsByMatchIDs, subscribe } from '../tipsStore.js';
  import { getLeaderboard } from '../utils/leaderboards.js';
  import { getBadges } from '../utils/badges.js';
  import { formatRank } from '../utils/formatting.js';

  export let user;
  let userTips = [];
  const urlParams = new URLSearchParams(window.location.search);
  const round = urlParams.get('round');
  // TODO - should validate if it's a valid round...
  let selectedRound = parseInt(round || $currentRound);
  let userRank;
  let leaderboardData = [];

  async function updateRank() {
    console.log('fetching leaderboard data');
    leaderboardData = await getLeaderboard('overall');
    userRank = leaderboardData.findIndex((i) => i.id === user.id) + 1;
  }
  updateRank();

  subscribe((value) => {
    userTips = value;
  });
  let userTipsUnsubscribe = [];

  $: roundSummary = userTips ? summariseRoundData() : null;

  function summariseRoundData() {
    let totalInvested = 0;
    let totalWon = 0;
    let totalLost = 0;
    let totalPending = 0;
    let pendingTipsCount = 0;
    let potentialReturn = 0;

    userTips.forEach((tip) => {
      if (tip.settled) {
        if (tip.winner === true) {
          totalWon += tip.stake * tip.odds - tip.stake;
        } else if (tip.winner === false && tip.isDraw !== true) {
          totalWon -= tip.stake;
          totalLost += tip.stake;
        }
      } else {
        totalPending += tip.stake;
        pendingTipsCount++;
        potentialReturn += tip.stake * tip.odds; // should potentialReturn be calculated by also subtracting the stake?
      }
      totalInvested += tip.stake;
    });

    return {
      totalInvested,
      totalWon,
      totalLost,
      totalPending,
      pendingTipsCount,
      potentialReturn,
    };
  }

  let roundLeaderboard = [];
  $: userRoundRank = roundLeaderboard.findIndex((i) => i.id === user.id) + 1;

  let badgeData = {};
  let userBadges = [];

  onMount(async () => {
    // Is it useful to have selectedRound stored? Can't reproduce that weird loading bug...
    // if there's a selectedRound, that should override the currentRound...
    // so we don't ever "getCurrentRound" if there is a selectedRound
    roundLeaderboard = await getLeaderboard(selectedRound);
    await getFixtures(selectedRound);
    badgeData = $badges || (await getBadges());
    badges.set(badgeData);
    userBadges = badgeData[user.id] || [];
    const unsub = getTipsByMatchIDs(
      user.id,
      $fixtures[selectedRound].map((match) => match.doc_id)
    );
    userTipsUnsubscribe.push(unsub);
  });

  onDestroy(() => {
    userTipsUnsubscribe.forEach((unsub) => unsub());
  });
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Flex justify="flex-start">
    <Icon margin="0px 10px 0px 0px" name="dollar" />
    <Heading>{`${user.name}`}</Heading>
  </Flex>
  <Flex>
    <Text margin="3px 0px 0px 0px" fontColor="#999" fontSize="11px"
      >joined: {new Date(user.joined).toLocaleString('default', {
        month: 'long',
      })}, {new Date(user.joined).getFullYear()}
    </Text>
    <Link to={`/wallet/${user.id}/history`}>
      <Flex alignItems="center" padding="0px 0px 0px 0px">
        <Text fontSize="13px" fontColor="#999">activity</Text>
        <Icon
          height="11px"
          margin="2px 0px 0px 5px"
          name="arrow"
          filter="invert(78%) sepia(3%) saturate(10%) hue-rotate(339deg) brightness(79%) contrast(81%)"
        />
      </Flex>
    </Link>
  </Flex>
  <!-- <Flex justify="flex-start" alignItems="center">
    <Text fontColor="#999" fontSize="11px" margin="0px 5px 0px 0px">form:</Text>
    <Form form={user.form.slice(-10)} />
  </Flex> -->
  <VerticalSpacer height="5px" />
  <Flex direction="column">
    {#if userBadges.length > 0}
      <DataCard
        border="solid 4px #FEDE47"
        hideValue={true}
        label={`badges`}
        margin="10px -10px 10px 0px"
      >
        <Flex justify="flex-start" overflowX="auto">
          {#each userBadges as badge}
            <Flex
              direction="column"
              alignItems="center"
              padding="0px 20px 3px 0px"
            >
              <!-- using @html to ensure that &thinsp; is rendered correctly -->
              <Icon name={badge.b} margin="4px 0px 3px 0px" /><Text
                textAlign="center"
                fontSize="9px"
                fontWeight="600"
                >{@html badge.l.replace(/ /g, '&thinsp;')}</Text
              >
            </Flex>
          {/each}
        </Flex>
      </DataCard>
    {/if}

    <Flex justify="flex-start">
      <DataCard
        border="solid 4px #FEDE47"
        value={`${formatRank(userRank)} of ${leaderboardData.length}`}
        label={`overall rank`}
      />
      <DataCard
        border="solid 4px #FEDE47"
        hideValue={true}
        label={`last ${user.form.slice(-10).length} tips`}
        margin="10px 0px 10px 0px"
        ><Form form={user.form.slice(-10)} /></DataCard
      >
    </Flex>
    <Flex justify="flex-start" margin="0px 0px 10px 0px" overflowX="auto">
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${user.netWorth.toFixed(2)}`}
        label={`net worth`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${user.walletTotal.toFixed(2)}`}
        label={`available funds`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${(user.netWorth - user.walletTotal).toFixed(2)}`}
        label={`currently invested`}
      />
    </Flex>
  </Flex>
  <VerticalSpacer height="30px" />
  <Flex>
    <Text fontSize="13px" fontColor="#333" fontWeight="600">tips by round</Text>
    <Link to={`/wallet/${user.id}/tips`}>
      <Flex alignItems="center">
        <Text fontSize="13px" fontColor="#999">history</Text>
        <Icon
          height="11px"
          margin="2px 0px 0px 5px"
          name="arrow"
          filter="invert(78%) sepia(3%) saturate(10%) hue-rotate(339deg) brightness(79%) contrast(81%)"
        />
      </Flex>
    </Link>
  </Flex>
  <VerticalSpacer height="5px" />
  <div style="margin: 0px 0px 0px -6px;">
    <RoundSelector
      {selectedRound}
      onRoundSelect={async (round) => {
        console.log('round selected', round);
        selectedRound = round;
        roundLeaderboard = await getLeaderboard(round);
        await getFixtures(selectedRound);
        const unsub = getTipsByMatchIDs(
          user.id,
          $fixtures[selectedRound].map((match) => match.doc_id)
        );
        userTipsUnsubscribe.push(unsub);
      }}
    />
  </div>

  {#if roundSummary}
    <Flex justify="flex-start" margin="0px 0px 10px 0px" overflowX="auto">
      <DataCard
        border="solid 4px #FEDE47"
        headerFontSize="13px"
        footerFontSize="11px"
        value={userRoundRank > 0 ? `${formatRank(userRoundRank)}` : '-'}
        label={`round rank`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${roundSummary.totalWon.toFixed(2)}`}
        label={`won`}
      />
      {#if user.roundsSummary && user.roundsSummary[selectedRound] && user.roundsSummary[selectedRound].hasOwnProperty('takeBack')}
        <DataCard
          border="solid 4px #EB5757"
          headerFontSize="13px"
          footerFontSize="11px"
          value={`$${user.roundsSummary[selectedRound].takeBack.toFixed(2)}`}
          label={`takesies backsies`}
        />
      {/if}
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${roundSummary.totalInvested.toFixed(2)}`}
        label={`total invested`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${roundSummary.totalPending.toFixed(2)}`}
        label={`currently pending`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`${roundSummary.pendingTipsCount}`}
        label={`pending tips`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value="{userTips.length || '0'}/{$fixtures[selectedRound]
          ? $fixtures[selectedRound].length
          : '–'}"
        label={`games tipped`}
      />
      <DataCard
        headerFontSize="13px"
        footerFontSize="11px"
        value={`$${roundSummary.potentialReturn.toFixed(2)}`}
        label={`potential return`}
      />
    </Flex>
  {/if}

  {#if user.roundsSummary && user.roundsSummary[selectedRound] && user.roundsSummary[selectedRound].hasOwnProperty('takeBack')}
    <ThickBorder
      borderColor="red"
      padding="10px"
      dropShadow={null}
      margin="10px"
    >
      <Text fontWeight="600">Doh! Free funds went unused for this round.</Text>
      <VerticalSpacer height="5px" />
      <Text fontSize="13px" margin="0px 0px 4px 0px"
        >Each round you get $100 fake money to tip with – if you don't use it
        all, we have to do takesies-backsies on the difference.</Text
      >
    </ThickBorder>
  {/if}

  <Flex direction="column" align="flex-start">
    <VerticalSpacer height="15px" />
    {#if $fixtures && $fixtures[selectedRound] && userTips}
      <div class="matches-container">
        {#each $fixtures[selectedRound] as singleMatchData, i}
          {#if userTips.find((tip) => tip.matchId === singleMatchData.doc_id)}
            <div in:fade={{ delay: i * 50, duration: 250 }}>
              <SingleMatch
                canTip={$userStore.id === user.id}
                docId={singleMatchData.doc_id}
                matchData={{ ...singleMatchData }}
                {userTips}
              />
              <VerticalSpacer />
            </div>
          {/if}
        {/each}
        <VerticalSpacer height="20px" />
        <Flex justify="center">
          <Link to={`/?round=${selectedRound}`}>
            <ThickBorder borderColor="darkGrey">
              <Text
                fontColor="#333"
                fontWeight="600"
                fontSize="13px"
                margin="10px 10px">view all matches</Text
              >
            </ThickBorder>
          </Link>
        </Flex>
      </div>
    {:else}
      <LoadingText />
    {/if}
  </Flex>
  <VerticalSpacer height="100px" />
</PageContainer>
