<script>
  import { Flex, Text, Heading, PageContainer, VerticalSpacer } from '../components/ui';
  import { Link } from 'svelte-navigator';
</script>

<PageContainer>
  <Flex direction="column" alignItems="center">
    <VerticalSpacer height="20px" />
    <Heading>Bah bow.</Heading>
    <Text>Nothing here... Check your link is correct.</Text>
    <Text>Or start again from the <Link to="/">home page</Link></Text>
  </Flex>
</PageContainer>
