<script>
  import { fade } from "svelte/transition";
  import "nes.css/css/nes.min.css";
  import Nav from "./components/Nav.svelte";
  import Toasts from "./components/alerts/Toasts.svelte";
  import Tipping from "./pages/Tipping.svelte";
  import { LogoLoading } from "./components/ui";
  import UserWallet from "./UserWallet.svelte";
  import Login from "./pages/Login.svelte";
  import Rules from "./pages/Rules.svelte";
  import AllSports from "./pages/AllSports.svelte";
  import Leaderboard from "./pages/Leaderboard.svelte";
  import Leagues from "./pages/Leagues.svelte";
  import CreateLeague from "./components/CreateLeague.svelte";
  import SingleLeague from "./pages/SingleLeague.svelte";
  import UILibrary from "./pages/UILibrary.svelte";
  import UserSettings from "./pages/UserSettings.svelte";
  import BlogPost from "./pages/BlogPost.svelte";
  import BlogList from "./pages/BlogList.svelte";
  import WalletHistory from "./pages/WalletHistory.svelte";
  import TipsHistory from "./pages/TipsHistory.svelte";
  import NothingHere from "./pages/NothingHere.svelte";
  import { BackButton } from "./components/ui";
  import {
    loadingUser,
    userStore,
    competitionStatus,
    currentRound,
    rounds,
  } from "./stores";
  import { createUserStore } from "./userStore.js";
  import { Router, Route } from "svelte-navigator";
  import Footer from "./components/Footer.svelte";
  import Privacy from "./pages/Privacy.svelte";
  import { onDestroy, onMount } from "svelte";
  import { getCurrentRound } from "./utils/fixtures";

  let user;
  $: loaded = (typeof $currentRound === "number") && $competitionStatus && $rounds && !$loadingUser;
  let userStoreUnsubscribe;

  let sport = "__sport__";
  const sportName = {
    afl: "Aussie Rules",
    nrl: "Rugby League",
    test: "testing",
  }[sport];

  // Could get more dynamic with the pageName
  // import { pageName } from "./stores.js";
  // $: title = $pageName ? `Odd Tipping — __sport__ – ${$pageName}` : 'Odd Tipping';
  $: title = `Odd Tipping - ${sportName}`;

  userStore.subscribe((value) => {
    if (value && value.id) {
      // here we're creating a listener on the db for real-time updates (eg. wallet balance)
      // only create listener if there is no unsubscribe function already
      if (!userStoreUnsubscribe) {
        // console.log("create user store", value.id);
        userStoreUnsubscribe = createUserStore(value.id);
      }
    }
    user = value;
  });

  onMount(() => {
    // getCurrentRound triggers the meta collection data to update in stores
    getCurrentRound();
  });

  onDestroy(() => {
    if (userStoreUnsubscribe) {
      userStoreUnsubscribe();
    }
  });
</script>

<Toasts />
<Router>
  {#if loaded}
    {#if $userStore}
      <Nav />

      <main>
        <div
          class="app-container"
          transition:fade={{ delay: 350, duration: 500 }}
        >
          <BackButton />

          <Route path="wallet/:id" component={UserWallet} />

          <Route path="wallet/:id/history" component={WalletHistory} />

          <Route path="wallet/:id/tips" component={TipsHistory} />

          <Route path="leaderboard" component={Leaderboard} />

          <Route path="rules" component={Rules} />

          <Route primary={false} path="user/:id" component={UserWallet} />

          <Route path="leagues" component={Leagues} />

          <Route path="leagues/:id" component={SingleLeague} />

          <Route {user} path="leagues/create" component={CreateLeague} />

          <Route path="/"><Tipping /></Route>

          <Route path="sports" component={AllSports} />

          <Route path="privacy" component={Privacy} />

          <Route path="settings"><UserSettings /></Route>

          <Route path="blogs"><BlogList /></Route>

          <Route path="blog/:id" component={BlogPost} />

          <Route path="ui-library"><UILibrary /></Route>

          <Route path="*"><NothingHere /></Route>
        </div>
      </main>
      <Footer />
    {:else}
      <Nav loggedIn={false} />
      <main>
        <div
          class="app-container"
          transition:fade={{ delay: 350, duration: 500 }}
        >
          <BackButton />

          <Route path="/"><Login /></Route>

          <Route path="blogs"><BlogList /></Route>

          <Route path="blog/:id" component={BlogPost} />

          <Route path="rules" component={Rules} />

          <Route path="privacy" component={Privacy} />

          <Route path="sports" component={AllSports} />

          <Route path="*"><Login /></Route>
        </div>
      </main>
      <Footer />
    {/if}
  {:else}
    <div class="full-page">
      <LogoLoading />
    </div>
  {/if}
</Router>
<svelte:head>
  <title>{title}</title>
  <!-- TODO - add a dynamic logo, icons etc. -->
</svelte:head>

<style>
  .app-container {
    margin-top: 65px;
    height: 100%;
  }
  .full-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
