<script>
  import { onMount } from 'svelte';
  import { Text, ThickBorder, Box } from '../components/ui';
  import { currentRound, rounds } from '../stores';

  export let onRoundSelect;
  export let extraRounds = [];

  export let selectedRound = null; // =  $currentRound;
  let currentRoundElement;
  let roundsContainerElement;

  const urlParams = new URLSearchParams(window.location.search);

  onMount(async () => {
    selectedRound = selectedRound || $currentRound;
    setTimeout(() => {
      const scrollOffset =
        currentRoundElement.offsetLeft - roundsContainerElement.offsetLeft;
      roundsContainerElement.scrollLeft = scrollOffset - 15;
      // console.log('scrollOffset', scrollOffset);
    }, 500);
  });
</script>

<div id="rounds-container" bind:this={roundsContainerElement}>
  {#each [...extraRounds, ...$rounds] as round, i}
    {#if round === selectedRound}
      <div bind:this={currentRoundElement}>
        <ThickBorder
          dropShadow="0px 0px 0px #FFF"
          backgroundColor="#fede47"
          borderColor={round === $currentRound ? 'yellow' : 'grey'}
          margin="2px 6px 6px 6px"
        >
          <Box display="flex" padding="17px 15px" height="30px">
            <Text fontWeight="600">{round}</Text>
          </Box>
        </ThickBorder>
      </div>
    {:else}
      <ThickBorder
        borderColor={round === $currentRound ? 'yellow' : 'grey'}
        dropShadow="md"
        margin="2px 6px 6px 6px"
      >
        <div
          on:click={() => {
            selectedRound = round;
            onRoundSelect(round);
            // update url round param with `round`
            urlParams.set('round', round);
            window.history.replaceState({}, '', '?' + urlParams.toString());
          }}
        >
          <Box display="flex" padding="17px 15px" height="30px">
            <Text>{round}</Text>
          </Box>
        </div>
      </ThickBorder>
    {/if}
  {/each}
</div>

<style>
  #rounds-container {
    padding: 10px 0px 10px 5px;
    display: flex;
    direction: row;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    -webkit-mask: linear-gradient(90deg, #0000, #000 2% 98%, #0000);
  }

  #rounds-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
</style>
