<script>
  import { onMount, onDestroy } from "svelte";
  import { useNavigate, useLocation } from "svelte-navigator";
  import { Text, Icon } from "../components/ui";
  import { fade } from "svelte/transition";

  const navigate = useNavigate();
  const location = useLocation();
  let historyStack = [];

  $: hideBackButton =
    historyStack.length < 1 ||
    (historyStack.length === 1 && historyStack[0] === $location.pathname);

  let unlisten;
  onMount(() => {
    unlisten = location.subscribe(({ pathname }) => {
      if (historyStack[historyStack.length - 1] !== pathname) {
        historyStack = [...historyStack, pathname];
      }
    });
  });

  onDestroy(() => {
    if (unlisten) {
      unlisten();
    }
  });

  function goBack() {
    historyStack = [...historyStack];
    historyStack.pop();
    if (historyStack.length > 0) {
      navigate(historyStack[historyStack.length - 1]);
    }
  }
</script>

{#if !hideBackButton}
  <div
    transition:fade={{ delay: 50, duration: 250 }}
    class="back-button"
    on:click={goBack}
  >
    <Icon
      height="12px"
      margin="0px 5px 0px 0px"
      name="arrow"
      transform="rotate(180deg)"
    /><Text fontSize="13px">back</Text>
  </div>
{/if}

<style>
  .back-button {
    margin: 0px 0px 10px 10px;
    align-items: center;
    display: flex;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC)
        14 0,
      pointer;
  }
</style>
