<script>
  import Icon from "./Icon.svelte";
  export let heading = " ";
  export let margin = "0px 0px";
  export let padding = "0px 0px";
  export let icon = null;
  import ThickBorder from "./ThickBorder.svelte";
</script>

<ThickBorder dropShadow="null" borderColor="grey" {padding} {margin}>
  <p style="display: flex; align-items: center;" class="title">
    {#if icon}<Icon margin="0px 10px -5px 0px" name={icon} /> {/if}
    <span>{heading}</span>
  </p>
  <slot />
</ThickBorder>

<style>
  p span {
    position: relative;
    top: 4px;
    /*     top: 2px;
    font-family: "Source Code Pro", monospace;
    font-weight: 600;
    font-size: large; */
  }
  .title {
    padding: 0px 0px !important;
    margin: 0px 0px 16px 0px !important;
  }
</style>
