<script>
  export let colour = "green"; // this seems wrong here?
  const backgroundColour =
    {
      neutral: "#212529",
      primary: "#209cee", // #007AFF
      success: "#4BB543",
      warning: "#e76e55",
      light: "#999999"
    }[colour] ?? "gray";
</script>

<div style="position: relative;">
  <sl-badge style="--colour:{backgroundColour};"><slot /></sl-badge>
  <div class="corner" id="top-left" />
  <div class="corner" id="top-right" />
  <div class="corner" id="bottom-left" />
  <div class="corner" id="bottom-right" />
</div>

<style>
  sl-badge::part(base) {
    font-family: "Source Code Pro", monospace;
    border-radius: 0px;
    background-color: white;
    border-color: var(--colour);
    color: var(--colour);
    font-weight: 600;
    border-width: 1.5px;
    /* border-style: solid; */
    height: 24px !important;
    padding: 0px 0px !important;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05)); */
  }
  .corner {
    width: 1.5px;
    height: 1.5px;
    background-color: #fff;
  }

  #top-left {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  #top-right {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  #bottom-left {
    position: absolute;
    top: 22.5px;
    left: 0px;
  }

  #bottom-right {
    position: absolute;
    top: 22.5px;
    right: 0px;
  }
</style>
