import { db } from '../firebase/';
import {
  collection,
  doc,
  query,
  orderBy,
  limit,
  getDocs,
  getDoc,
  startAfter,
} from 'firebase/firestore';

import {
  viewedUserWalletStore,
  viewedUserTipsStore,
  viewedUserStore,
  viewedUserId,
} from '../stores';

let currentViewedUser;
viewedUserId.subscribe((val) => {
  currentViewedUser = val;
});

export const getViewedUserData = async (id) => {
  const viewedUserRef = doc(db, '__users_collection__', id);
  const viewedUserSnap = await getDoc(viewedUserRef);
  if (!viewedUserSnap.exists()) return false;
  const viewedUser = viewedUserSnap.data();
  viewedUserStore.set({ ...viewedUser });
  return true;
};

export const getViewedUserWallet = async (id, lastDoc) => {
  let viewedUserWalletQuery;
  if (!lastDoc) {
    viewedUserWalletQuery = query(
      collection(db, '__users_collection__', id, 'wallet'),
      orderBy('updated', 'desc'),
      limit(10)
    );
  } else {
    viewedUserWalletQuery = query(
      collection(db, '__users_collection__', id, 'wallet'),
      orderBy('updated', 'desc'),
      startAfter(lastDoc),
      limit(10)
    );
  }

  const viewedUserWalletSnap = await getDocs(viewedUserWalletQuery);
  const viewedUserWallet = viewedUserWalletSnap.docs.map((doc) => doc.data());
  if (currentViewedUser === id && lastDoc) {
    viewedUserWalletStore.update((curr) => [...curr, ...viewedUserWallet]);
  } else {
    viewedUserWalletStore.set(viewedUserWallet);
  }

  return viewedUserWalletSnap.docs[viewedUserWalletSnap.docs.length - 1];
};

export const getViewedUserTips = async (id, lastDoc) => {
  let viewedUserTipsQuery;
  if (!lastDoc) {
    viewedUserTipsQuery = query(
      collection(db, '__users_collection__', id, 'tips'),
      orderBy('starttime', 'desc'),
      limit(10)
    );
  } else {
    viewedUserTipsQuery = query(
      collection(db, '__users_collection__', id, 'tips'),
      orderBy('starttime', 'desc'),
      startAfter(lastDoc),
      limit(10)
    );
  }

  const viewedUserTipsSnap = await getDocs(viewedUserTipsQuery);
  const viewedUserTips = viewedUserTipsSnap.docs.map((doc) => doc.data());

  if (currentViewedUser === id && lastDoc) {
    viewedUserTipsStore.update((curr) => [...curr, ...viewedUserTips]);
  } else {
    viewedUserTipsStore.set(viewedUserTips);
  }
  return viewedUserTipsSnap.docs[viewedUserTipsSnap.docs.length - 1];
};
