<script>
  import { fade } from "svelte/transition";
  export let loading;
  const sport = "__sport__";
  let iconPath = "/images/footy-icon.png"; // default is afl
  if (sport === "nrl") {
    iconPath = "/images/league-ball-icon.png";
  }
</script>

<div class="loading-spinner">
  {#if loading}
    <img
      transition:fade={{ delay: 100, duration: 500 }}
      src={iconPath}
      class="rotating"
      alt="footy icon"
    />
  {/if}
</div>

<style>
  .loading-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 40px;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s steps(6) infinite;
    -moz-animation: rotating 2s steps(6) infinite;
    -ms-animation: rotating 2s steps(6) infinite;
    -o-animation: rotating 2s steps(6) infinite;
    animation: rotating 2s steps(6) infinite;
  }
</style>
