<script>
  export let direction = 'row';
  export let align = 'center';
  export let justify = 'space-between';
  export let padding = '0px 0px';
  export let onClick = null;
  export let margin = '0px 0px';
  export let alignItems = 'normal';
  export let borderLeft = 'none';
  export let wrap = 'nowrap';
  export let overflowX = 'visible';
</script>

<div
  on:click={onClick}
  style="--padding: {padding}; --margin: {margin}; --flex-direction: {direction}; --align-items: {align}; --justify-content: {justify}; --align-items: {alignItems}; --border-left: {borderLeft}; --flex-wrap: {wrap}; --overflow-x: {overflowX};"
>
  <slot />
</div>

<style>
  div {
    position: relative;
    display: flex;
    padding: var(--padding);
    margin: var(--margin);
    flex-direction: var(--flex-direction);
    align-content: var(--align-items);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    border-left: var(--border-left);
    flex-wrap: var(--flex-wrap);
    scrollbar-width: none;
    overflow-x: var(--overflow-x);
  }
</style>
