<script>
  import { Flex, Text, Icon } from '../components/ui';
  export let form = [];
  const iconHeight = '13px';
  const iconMargin = '0px 5px 0px 0px';
</script>

<Flex justify="flex-start" margin="5px 0px">
  {#if form.length === 0}
    <Text margin="3px 0px 0px 0px" fontSize="12px" fontColor="#999">–</Text>
  {/if}
  {#each form as item}
    {#if item === 'W'}
      <Icon margin={iconMargin} height={iconHeight} name="coin_check" />
    {:else if item === 'L'}
      <Icon margin={iconMargin} height={iconHeight} name="coin_cross" />
    {:else if item === 'D'}
      <Icon margin={iconMargin} height={iconHeight} name="coin_draw" />
    {/if}
  {/each}
</Flex>