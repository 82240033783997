import { writable } from "svelte/store";
import { db } from "./firebase";
import { collection, where, query, onSnapshot, doc } from "firebase/firestore";
import { userStore } from "./stores";

function createUserStore(userId) {
  const unsubscribe = onSnapshot(
    doc(db, "__users_collection__", userId),
    (doc) => {
      userStore.set(doc.data());
    }
  );
  return unsubscribe;
}

export { createUserStore };
