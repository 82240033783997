<script>
  import { Flex, Text } from '../components/ui';
  export let label = '';
  export let value = '';
  export let border = 'solid 1px #d9d9d9';
  export let headerFontSize = '16px';
  export let footerFontSize = '13px';
  export let hideValue = false;
  export let margin = '10px 30px 10px 0px';
</script>

<Flex
  direction="column"
  padding="0px 0px 0px 10px"
  margin={margin}
  borderLeft={border}
  justify="flex-start"
>
  {#if !hideValue}
    <Text fontWeight="600" fontSize={headerFontSize}>{value}</Text>
  {/if}
  <slot />
  <Text fontColor="#999" fontSize={footerFontSize}>{label}</Text></Flex
>
