<script>
  import { fade } from "svelte/transition";
  import {
    Heading,
    Icon,
    Flex,
    VerticalSpacer,
    Divider,
    PageContainer,
  } from "../components/ui";
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Flex justify="flex-start">
    <Icon name="help" margin="0px 10px 0px 0px" />
    <Heading>how to play</Heading></Flex
  >
  <VerticalSpacer height="20px" />
  <p>Odd Tipping is a footy tipping game with a twist.</p>
  <p>
    To play, you stake “Monopoly money” on footy games based on real(ish) odds.
  </p>
  <p>
    A winning tip will get you the value of the tip added to your wallet of fake
    funds.
  </p>
  <p>
    Every round, all players receive $100, so you can keep up hope of a comeback
    even when you lose it all every week like a dumbass.
  </p>
  <p>
    The person with the most fake money at the end of the season is the winner.
  </p>
  <p>
    Because of the obvious connection to gambling, Odd Tipping has a recommended
    age rating of 18+ ...But it's just fake money. By these standards Monopoly
    should be “adults only”.
  </p>
  <VerticalSpacer height="40px" />
  <Divider />
  <VerticalSpacer height="20px" />
  <Heading>rules</Heading>
  <ul>
    <li>
      Every round, every user is issued 100 tipping credits to use in addition
      to credits already in their wallet.
    </li>
    <li>
      The credits you receive each round are "use it or lose it", meaning if you
      only use $80 of the $100, at the end of the round, $20 will be taken back
      from your wallet. This is to ensure "ghost" players don't win.
    </li>
    <li>Clearly, the credits issued in this game are not real money.</li>
    <li>
      Games are open for tipping if they have odds and the start time has not
      passed (tips can be edited and submitted up until each game's start time).
    </li>
    <li>
      Unlike traditional tipping, there is no incentive to tip a game in which
      you have no confidence in tipping the winner.
    </li>
    <li>
      To place a tip you must select a team, input a valid number amount (your
      'stake') that is less than the current credits of your 'wallet' and
      'submit/update' the tip.
    </li>
    <li>
      Your 'wallet' displays how much credit you have available for placing tips
      as well as any pending or settled tips for your account.
    </li>
    <li>
      For each successful tip, your wallet will be credited the total of your
      'stake' for that match, multiplied by the odds of the tipped team as of
      the time of submitting your tip.
    </li>
    <li>
      Successful tips are aimed to be credited to your wallet within 2 hours of
      the game's completion, but no guarantees are made on this timing.
    </li>
    <li>
      Any won credit can be used immediately for any games currently open for
      tipping.
    </li>
    <li>
      Odds are based on the average odds provided by a selection of bookmakers,
      not one in particular.
    </li>
    <li>
      The odds are potentially subject to change during the leadup to a match.
    </li>
    <li>
      Currently, there is no prize for winning Odd Tipping (other than pride, of
      course!).
    </li>
  </ul>
  <VerticalSpacer height="20px" />
</PageContainer>

<style>
  li {
    font-family: "Source Code Pro", monospace;
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  ul {
    padding-left: 20px;
  }

  p {
    font-family: "Source Code Pro", monospace;
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 16px;
  }
</style>
