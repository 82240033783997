<script>
  export let fontSize = '1em';
  export let fontColor = '#000';
  export let lineHeight = '1.5';
  export let fontWeight = 'normal';
  export let display = 'block';
  export let margin = '0px 0px 0px 0px';
</script>

<span
  style="--display: {display}; --line-height: {lineHeight}; --font-size: {fontSize}; --font-color: {fontColor}; --font-weight: {fontWeight}; --margin: {margin};"
  ><slot /></span
>

<style>
  span {
    font-size: var(--font-size);
    font-family: 'Source Code Pro', monospace;
    color: var(--font-color);
    font-weight: var(--font-weight);
    display: var(--display);
    margin: var(--margin);
    line-height: var(--line-height);
  }
</style>
