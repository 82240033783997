import { writable } from "svelte/store";
import { db } from "./firebase";
import { collection, where, query, onSnapshot } from "firebase/firestore";

export default function createUserLeagueStore(userId) {
  const { subscribe, set, update } = writable({});

  const leaguesQuery = query(
    collection(db, "__leagues_collection__"),
    where("users", "array-contains", userId)
  );
  const unsubscribe = onSnapshot(leaguesQuery, (querySnapshot) => {
    const userLeagues = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    set(userLeagues);
  });
  return { subscribe, unsubscribe };
}
