<script>
  export let name = "";
  export let margin = "";
  export let height = "25px";
  export let filter = "";
  export let transform = "";
  // for filter colours: https://codepen.io/sosuke/pen/Pjoqqp
</script>

<img
  style="--margin:{margin}; --height:{height}; --filter:{filter}; --transform:{transform};"
  src={`/images/${name}.svg`}
  alt="icon"
/>

<style>
  img {
    height: var(--height);
    margin: var(--margin);
    filter: var(--filter);
    transform: var(--transform);
  }
</style>
