<script>
  import {
    Text,
    Heading,
    Flex,
    LoadingText,
    PageContainer,
    Button,
    VerticalSpacer
  } from "../components/ui";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";
  import { getViewedUserData, getViewedUserWallet } from "../utils/wallets";

  import {
    viewedUserStore,
    viewedUserWalletStore,
    viewedUserWalletLastDoc,
  } from "../stores";
  export let id;

  let loading = true;
  let allowLoadMoreWallet = true;

  $: walletHistory = $viewedUserWalletStore
    ? $viewedUserWalletStore
        .map((entry) => ({
          versus: `${entry?.matchId?.split("_")[1]} vs ${
            entry?.matchId?.split("_")[2]
          }`,
          ...entry,
        }))
        .sort((a, b) => b.updated - a.updated)
    : [];

  onMount(async () => {
    const isUser = await getViewedUserData(id);
    if (!isUser) {
      loading = false;
      return;
    }
    const walletLastDoc = await getViewedUserWallet(id);
    viewedUserWalletLastDoc.set(walletLastDoc);
    loading = false;
  });
</script>

<PageContainer>
  {#if loading}
    <div class="loading-container">
      <LoadingText />
    </div>
  {:else if $viewedUserStore !== null && $viewedUserStore.id === id}
    <div in:fade={{ delay: 100, duration: 500 }}>
      <VerticalSpacer height="20px" />
      <Heading margin="0px 0px 10px 0px">wallet activity</Heading>
      {#if walletHistory.length > 0}
        <table>
          <tr>
            <th><Text fontWeight="300" fontSize="small">match</Text></th>
            <th class="td-center"
              ><Text fontWeight="300" fontSize="small">type</Text></th
            >
            <th class="td-center"
              ><Text fontWeight="300" fontSize="small">change</Text></th
            >
          </tr>
          {#each walletHistory as walletEntry}
            <tr>
              <td><Text fontSize="small">{walletEntry.title}</Text></td>
              <td class="td-center"
                ><Text fontSize="small">{walletEntry.type}</Text></td
              >
              <td class="td-center">
                <Text fontSize="small"
                  >${walletEntry.changeAmount.toFixed(2).split(".00")[0]}</Text
                >
              </td>
            </tr>
          {/each}
        </table>
        <Flex justify="center" margin="20px 0px 0px 0px">
          <Button
            disabled={!allowLoadMoreWallet || !$viewedUserWalletLastDoc}
            fontSize="small"
            onClick={async () => {
              if (!allowLoadMoreWallet || !$viewedUserWalletLastDoc) return;
              let walletLastDoc = await getViewedUserWallet(
                id,
                $viewedUserWalletLastDoc
              );
              if (walletLastDoc) {
                viewedUserWalletLastDoc.set(walletLastDoc);
              } else {
                allowLoadMoreWallet = false;
              }
            }}>load more</Button
          >
        </Flex>
      {:else}
        <p><span class="nes-text is-disabled">No wallet history.</span></p>
      {/if}
    </div>
  {:else}
    <Text>Bah bow. No one here... Check your link is correct.</Text>
  {/if}
</PageContainer>

<style>
  .loading-container {
    height: 80vh;
    width: 100%;
  }

  table {
    width: 100%;
  }

  td {
    text-align: left;
  }

  .td-center {
    text-align: center;
  }
</style>
