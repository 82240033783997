<script>
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import CreateAccount from '../components/CreateAccount.svelte';
  import SignIn from '../components/SignIn.svelte';
  import { Link } from 'svelte-navigator';
  import { competitionStatus } from '../stores';
  import {
    Text,
    Button,
    Icon,
    Flex,
    Box,
    VerticalSpacer,
    Lottie,
  } from '../components/ui';
  let loginDialog;
  let createAccountDialog;
  let joinLeagueDialog;
  let url;
  const sport = '__sport__';
  const sportName = {
    afl: 'aussie rules',
    nrl: 'rugby league',
    test: 'testing',
  }[sport];
  $: isLeagueURL = false;

  onMount(() => {
    url = window.location.pathname;
    isLeagueURL = window.location.pathname.includes('leagues');
    if (isLeagueURL)
      // should have a better way to do this...
      setTimeout(() => {
        joinLeagueDialog.show();
      }, 1000);
  });
</script>

<div class="login-container" in:fade={{ delay: 250, duration: 500 }}>
  <Flex margin="0px 10px" direction="column" alignItems="center">
    <div
      style="position:relative; display: flex; flex-direction: column; align-items: flex-end;"
    >
      <div in:fade={{ delay: 250, duration: 1000 }}>
        <Lottie autoplay={true} loop={false} name="ot-logo" width="100%" />
        <div style="margin: 10px 0px 10px 0px;position:relative;top:-50px;">
          <Flex justify="flex-end">
            <Text fontSize="small" fontWeight="600">{sportName}</Text>
          </Flex>
        </div>
      </div>
    </div>

    <Box width="250px" margin="0px 0px 50px 0px">
      <Text fontSize="medium"
        >It's just footy tipping, but like... actual fun.</Text
      >
    </Box>
    <Link to="/rules">
      <Flex margin="0px 0px 20px 0px">
        <Text fontSize="small">Bit more info on this thing</Text><Icon
          name="arrow"
          height="15px"
          margin="3px 3px"
        /></Flex
      >
    </Link>
    <Link to="/sports">
      <Flex>
        <Text fontSize="small">Other Odd Tipping sports</Text><Icon
          name="arrow"
          height="15px"
          margin="3px 3px"
        /></Flex
      >
    </Link>
  </Flex>

  <sl-dialog no-header on:load={() => {}} bind:this={joinLeagueDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="16px" fontWeight="600">This link requires a login</Text>
      <i
        class="nes-icon close is-small"
        on:click={() => joinLeagueDialog.hide()}
      />
    </Flex>
    <Text fontSize="medium">
      It looks like you're trying to access a tipping comp's league page.
    </Text>
    <VerticalSpacer />
    <Text fontSize="medium">
      You'll need to login or create an account first, then we'll take you to
      the league where you can join in the fun.
    </Text>
    <VerticalSpacer height="20px" />
    <Flex>
      <Button
        fontSize="medium"
        height="45px"
        iconRight="login"
        primary
        onClick={async () => {
          loginDialog.show();
        }}
      >
        sign in
      </Button>
      <Button
        fontSize="medium"
        height="45px"
        onClick={() => createAccountDialog.show()}>create account</Button
      >
    </Flex>
    <!-- <Button primary onClick={() => joinLeagueDialog.hide()}
      >view sign in options</Button
    > -->
    <VerticalSpacer />
  </sl-dialog>
  <Flex margin="30px 0px" direction="column">
    <Flex direction="column">
      <Text fontSize="small">Got an account?</Text>
      <VerticalSpacer height="5px" />
      <Button
        fontSize="medium"
        height="45px"
        iconRight="login"
        primary
        onClick={async () => {
          loginDialog.show();
        }}
      >
        sign in
      </Button>
    </Flex>
    <VerticalSpacer height="20px" />
    <Flex direction="column">
      <Text fontSize="small">New? Get in here, legend.</Text>
      <VerticalSpacer height="5px" />
      <Button
        fontSize="medium"
        height="45px"
        onClick={() => createAccountDialog.show()}>create account</Button
      >
    </Flex>
  </Flex>

  <sl-dialog no-header bind:this={loginDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="small">sign in</Text>
      <i class="nes-icon close is-small" on:click={() => loginDialog.hide()} />
    </Flex>
    <SignIn />
  </sl-dialog>

  <sl-dialog no-header bind:this={createAccountDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="16px" fontWeight="600">create account</Text>
      <i
        class="nes-icon close is-small"
        on:click={() => createAccountDialog.hide()}
      />
    </Flex>
    <CreateAccount />
  </sl-dialog>
</div>

<style>
  sl-dialog::part(body) {
    font-size: 13px;
    padding: 10px 10px;
    border-radius: 0px;
    border: solid black 3px;
  }

  .login-container {
    opacity: 1;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
</style>
