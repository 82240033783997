<script>
  import Text from './Text.svelte';
  export let teamName;
  export let teamOdds;
  // export let hometeam;
  export let tipped;
  export let inactive = false;
  import { slangTeams, teamColours } from '../utils/teams';
  import Flex from './Flex.svelte';
  $: tipColour =
    tipped?.winner === true
      ? '#4BB543'
      : tipped?.winner === false
        ? '#e76e55'
        : '#209cee';
</script>

<!--  height: 110px; -->
<div style={`${inactive ? 'opacity: 0.6;' : ''}`}>
  <Flex direction="column" alignItems="center" justify="flex-start">
    <div class="team-colours">
      <div style="background-color: {teamColours[teamName][0]}" />
      <div style="background-color: {teamColours[teamName][1]}" />
      <div style="background-color: {teamColours[teamName][2]}" />
    </div>

    <div class={`team-name`}>
      <Flex direction="column" alignItems="center">
        <Text fontSize="medium" fontWeight="bold">{slangTeams[teamName]}</Text>
        <Text fontSize="medium">
          {#if teamOdds}{teamOdds.toFixed(2)}{:else}-{/if}
        </Text>
      </Flex>
      <div class="tipped-info" style={`background-color: ${tipColour}30`}>
        <Text fontSize="small"
          >{#if tipped?.tip === teamName}${tipped.stake
              .toFixed(2)
              .replace(/[.,]00$/, '')} @ {tipped.odds.toFixed(2)}{/if}</Text
        >
      </div>
    </div>
  </Flex>
</div>

<style>
  .team-name {
    margin-top: 10px;
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .team-colours {
    border-radius: 50px;
    overflow: hidden;
    border: solid 1px #000;
    display: flex;
    flex-direction: row;
    width: 25px;
    height: 25px;
  }

  .team-colours div {
    height: 100%;
    width: 100%;
  }

  .tipped-info {
    margin: 5px 5px;
    padding: 0px 5px;
    border-radius: 3px;
    text-align: center;
  }
</style>
