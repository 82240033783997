import { writable } from "svelte/store";
import { db } from "./firebase";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  limit,
  where,
  getDocs,
} from "firebase/firestore";

const { subscribe, set, update } = writable([]);
let current;

export const getTipsByMatchIDs = (userId, matchIds) => {
  subscribe((value) => {
    current = value;
  });

  const tipsQuery = query(
    collection(db, "__users_collection__", userId, "tips"),
    where("matchId", "in", matchIds)
  );

  // we should be able to use this function for any user, but
  // may be a good idea to pass along their userID to filter on
  const unsubscribe = onSnapshot(tipsQuery, (querySnapshot) => {
    const userTips = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    set(userTips);
  });

  return unsubscribe;
};

export { subscribe };
