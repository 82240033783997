<script>
  import { LottiePlayer } from "@lottiefiles/svelte-lottie-player";
</script>

<LottiePlayer
  src="/images/odd-tipping-logo.json"
  autoplay={true}
  loop={true}
  controls={false}
  renderer="svg"
  background="transparent"
  width={360}
  height={150}
  controlsLayout={null}
/>
