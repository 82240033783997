export const slangTeams = {
  afl: {
    ADL: "CROWS",
    GEL: "CATS",
    SYD: "SWANS",
    BRI: "LIONS",
    WBD: "DOGS",
    COL: "PIES",
    ESS: "DONS",
    HAW: "HAWKS",
    STK: "SAINTS",
    GWS: "GIANTS",
    MEL: "DEES",
    FRE: "FREO",
    PA: "PORT",
    NM: "NORTH",
    RIC: "TIGERS",
    CAR: "BLUES",
    WCE: "EAGLES",
    GCS: "SUNS",
  },
  nrl: {
    BRI: "BRONCOS",
    PEN: "PENRITH",
    CBB: "BULLDOGS",
    GCO: "TITANS",
    CBR: "RAIDERS",
    MAN: "MANLY",
    CSS: "SHARKS",
    NEW: "KNIGHTS",
    DOL: "DOLPHINS",
    MBS: "STORM",
    SGI: "DRAGONS",
    SYD: "EASTS",
    SSY: "RABBITS",
    PAR: "EELS",
    WTG: "TIGERS",
    NQU: "COWBOYS",
    WAR: "WARRIORS",
  },
  test: {
    AUS: "STRAYA",
    ENG: "POMS",
    NZL: "KIWIS",
    IND: "INDIA",
    PAK: "PAKISTAN",
    SA: "SOUTH AFRICA",
  },
}["__sport__"];

export const teamColours = {
  afl: {
    ADL: ["#002b5c", "#ffd200", "#e21937"],
    GEL: ["#1c3c63", "#ffffff", "#1c3c63"],
    SYD: ["#ed171f", "#ffffff", "#ed171f"],
    BRI: ["#0055a3", "#fdbe57", "#a30046"],
    WBD: ["#014896", "#ffffff", "#c70136"],
    COL: ["#000000", "#ffffff", "#000000"],
    ESS: ["#cc2031", "#000000", "#cc2031"],
    HAW: ["#fbbf15", "#4d2004", "#fbbf15"],
    STK: ["#ed0f05", "#ffffff", "Black"],
    GWS: ["#f15c22", "#ffffff", "#384752"],
    MEL: ["#cc2031", "#0f1131", "#cc2031"],
    FRE: ["#2a1a54", "#ffffff", "#2a1a54"],
    PA: ["#000000", "#ffffff", "#008aab"],
    NM: ["#013b9f", "#ffffff", "#013b9f"],
    RIC: ["#000000", "#fed102", "#000000"],
    CAR: ["#0e1e2d", "#ffffff", "#0e1e2d"],
    WCE: ["#062ee2", "#ffd700", "#062ee2"],
    GCS: ["#d93e39", "#ffe600", "#d93e39"],
  },
  nrl: {
    BRI: ["#760135", "#fbbf15", "#760135"],
    PEN: ["#000000", "#00657C", "#e22726"],
    CBB: ["#0054a4", "#FFFFFF", "#0054a4"],
    GCO: ["#1e90ff", "#ffffff", "#ffd700"],
    CBR: ["#94c949", "#ffffff", "#273650"],
    MAN: ["#800000", "#ffffff", "#800000"],
    CSS: ["#87ceeb", "#ffffff", "#87ceeb"],
    NEW: ["#00539f", "#ffffff", "#ee3524"],
    DOL: ["#e2231b", "#ffffff", "#ECBE0C"],
    MBS: ["#800080", "#000080", "#ffd700"],
    SGI: ["#e2231b", "#ffffff", "#e2231b"],
    SYD: ["#00305e", "#ffffff", "#e82c2e"],
    SSY: ["#08800f", "#fd0018", "#08800f"],
    PAR: ["#006eb5", "#ffd327", "#006eb5"],
    WTG: ["#f68b1f", "#ffffff", "#000000"],
    NQU: ["#002b5c", "#ffffff", "#ffdd00"],
    WAR: ["#231f20", "#bdbcbc", "#231f20"],
  },
  test: {
    AUS: ["#006eb5", "#ffd327", "#006eb5"],
    ENG: ["#006eb5", "#ffd327", "#006eb5"],
    NZL: ["#006eb5", "#ffd327", "#006eb5"],
    IND: ["#006eb5", "#ffd327", "#006eb5"],
    PAK: ["#006eb5", "#ffd327", "#006eb5"],
    SA: ["#006eb5", "#ffd327", "#006eb5"],
  },
}["__sport__"];
