<script>
  import Button from "./Button.svelte";
  import TextInput from "./TextInput.svelte";
  import Text from "./Text.svelte";
  import VerticalSpacer from "./VerticalSpacer.svelte";
  import Flex from "./Flex.svelte";
  import { joinLeague } from "../utils/leagues";
  import { navigate } from "svelte-navigator";
  let leagueCode = "";
  import { addToast } from "../stores";
</script>

<Text  fontSize="small"
  >To join an existing league, enter its 'league code' below. If your mate has created a league, they'll have access to this code from their league page.</Text
>
<VerticalSpacer />
<TextInput bind:value={leagueCode} placeholder="your mate's league code..." />
<VerticalSpacer />
<Button
  fontSize="medium"
  disabled={!leagueCode}
  borderColor="darkGrey"
  onClick={async () => {
    try {
      await joinLeague(leagueCode);
      // TODO - I don't think I like this redirect. New league should
      // just appear in the space above and be clickable or highlighted
      navigate(`/leagues/${leagueCode}`);
      addToast({ message: "joined! redirecting to league...", type: "success", dismissible:true, timeout:3000 });
    } catch (error) {
      addToast({ message: "not found. try again", type: "error", dismissible:true, timeout:3000 });
    }
  }}>join league</Button
>

<style></style>
