<script>
  import { fade } from 'svelte/transition';
  import Heading from './Heading.svelte';
  const loadingMessages = [
    'loading stuff',
    'just a sec',
    'almost there',
    'be patient',
  ];
</script>

<div class="loading-container" in:fade={{ delay: 1000 }}>
  <Heading margin="0px 0px" fontColor="#999">{loadingMessages[Math.floor(Math.random() * loadingMessages.length)]}</Heading>
  <div style="width: 15px;">
    <span
    class="loading"
  ></span>
  </div>

</div>

<style>
  .loading-container {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
</style>
