// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFNLKb75v60LrerNBYXr_xQBC11jxylE0",
  authDomain: "odd-tipping.firebaseapp.com",
  projectId: "odd-tipping",
  storageBucket: "odd-tipping.appspot.com",
  messagingSenderId: "473206122194",
  appId: "1:473206122194:web:e6a3d8a9c2827366d12f0b",
  measurementId: "G-GQY7CJ9MR1",
};

import { loadingUser, userStore, userSigningIn } from "./stores";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

const auth = getAuth();
const db = getFirestore();

export const userSetup = httpsCallable(functions, "userSetup");

onAuthStateChanged(auth, async (u) => {
  const userCollection = "__users_collection__";
  if (u) {
    // User is signed in, see docs for a list of available properties
    if (u.uid) {
      // we need to account for the scenario where the user is signed in but not setup
      // this could happen if they login on a different subdomain for the first time, and don't
      // yet have a user doc for that subdomains competition. We need to check for this and potentially
      // run the userSetup function to create the user doc for this subdomain.
      const userCheck = await getDoc(doc(db, userCollection, u.uid));
      if (!userCheck.exists()) {
        if (u.displayName) {
          const userSetupResult = await userSetup({
            username: u.displayName,
            sport: "__sport__",
          });
          console.log("returning user userSetup required!");
        }
      }

      const userDoc = await getDoc(doc(db, userCollection, u.uid));
      // console.log("authchange", userDoc.data());
      userSigningIn.set(false);
      loadingUser.set(false);
      userStore.set(userDoc.data());
    }
  } else {
    loadingUser.set(false);
    userSigningIn.set(false);
    userStore.set(null);
    // User is signed out
  }
});

// connect to the local emulator functions during testing
if (__FB_EMULATORS__) {
  console.log("🤖 Connecting to emulators");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { db };
