<script>
    export let width = "10px";
</script>

<div style="--width: {width}">

</div>

<style>
    div {
        display: inline-block; 
        width: var(--width);
        height: 100%;
    }
</style>