<script>
  import Text from "../Text.svelte";
  import Flex from "../Flex.svelte";
  import Icon from "../Icon.svelte";
  import ThickBorder from "../ThickBorder.svelte";
  export let colour = "#000000"; //#209cee
  export let icon = "";
  export let transparentBackground = false;
</script>

{#if transparentBackground}
  <div id="blur">
    <ThickBorder borderColor="grey" backgroundColor="#ffffff">
      <Flex margin="10px 20px 10px 20px" justify="flex-start" alignItems="center">
        {#if icon} <Icon margin="0px 10px 0px 0px" name={icon} /> {/if}
        <Text fontColor={colour} ><slot /></Text>
      </Flex>
    </ThickBorder>
  </div>
{:else}
  <ThickBorder borderColor="grey" dropShadow="null">
    <Flex justify="flex-start" alignItems="center">
      {#if icon} <Icon margin="5px 20px 5px 20px" name={icon} /> {/if}
      <Text fontColor={colour} ><slot /></Text>
    </Flex>
  </ThickBorder>
{/if}

<style>
  #blur {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
</style>
