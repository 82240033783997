<script>
  import {
    PageContainer,
    Text,
    Divider,
    VerticalSpacer,
    Heading,
    Icon,
  } from "../components/ui";
  import { addToast } from "../stores";
  import { userStore } from "../stores";
  import { updateUserSettings } from "../utils/user-settings";
  $: userSettings = $userStore?.settings;
</script>

<PageContainer padding="6px 6px" heading="settings" icon="settings">
  <VerticalSpacer height="20px" />
  <Heading
    ><Icon
      margin="0px 10px 5px 0px"
      name="settings"
      height="20px"
    />Settings</Heading
  >
  <VerticalSpacer height="10px" />
  <Text>Here's where you change all the things you can change.</Text>
  <VerticalSpacer height="20px" />
  <Divider />
  <VerticalSpacer height="20px" />
  <Text fontWeight="600">email preferences</Text>
  <sl-checkbox
    size="large"
    on:sl-change={() => {
      updateUserSettings($userStore.id, {
        ...userSettings,
        weeklyUpdateEmail: !userSettings.weeklyUpdateEmail,
      }).then((result) => {
        if (result.success) {
          addToast({
            message: "updated email preferences",
            type: "success",
            dismissible: true,
            timeout: 3000,
          });
        } else {
          addToast({
            message: "failed to update email preferences",
            type: "error",
            dismissible: true,
            timeout: 3000,
          });
        }
      });
    }}
    checked={userSettings.weeklyUpdateEmail}>weekly update</sl-checkbox
  >
</PageContainer>

<style>
  sl-checkbox::part(label) {
    font-family: "Source Code Pro", monospace;
    color: #000;
  }

  sl-checkbox::part(control) {
    font-family: "Source Code Pro", monospace;
    border-color: #000;
    color: #000;
    background-color: #fff;
  }

</style>
