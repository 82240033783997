<script>
  import { Link } from "svelte-navigator";
  import { signOutUser } from "../utils/signout-user";
  import { navigate, useLocation } from "svelte-navigator";
  import { userStore } from "../stores";
  import { fly } from "svelte/transition";
  import { Box, Text, Icon, Logo, Flex, VerticalSpacer, Button } from "./ui";
  export let loggedIn = true;
  let drawer;
  const sport = "__sport__";
  const sportName = {
    afl: "aussie rules",
    nrl: "rugby league",
    test: "testing",
  }[sport];
  const location = useLocation();
  const megaMenuItems = [
    {
      name: "matches",
      icon: "calendar",
      link: "/",
    },
    {
      name: "wallet",
      icon: "dollar",
      link: `wallet/${$userStore?.id}`,
    },
    {
      name: "leagues",
      icon: "users",
      link: "leagues",
    },
    {
      name: "rankings",
      icon: "trophy",
      link: "leaderboard",
    },
    {
      name: "articles",
      icon: "note",
      link: "blogs",
    },
    {
      name: "how to play",
      icon: "help",
      link: "rules",
    },
    {
      name: "all sports",
      icon: "first",
      link: "sports",
    },
  ];
</script>

<nav in:fly={{ y: -60, duration: 500 }}>
  <div
    style="width: 100%; height:50px; display:flex; position: absolute; justify-content: center; background-color: #fff; box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);"
  >
    <div style="width: 100%; max-width: 760px">
      <Flex alignItems="center" padding="0px 15px 0px 0px">
        <Link to="/">
          <!-- fullLogo={!loggedIn}  -->
          <Logo />
        </Link>
        {#if loggedIn}
          <Link to="/">
            <Flex
              direction="column"
              margin="2px 5px 0px 0px"
              alignItems="center"
            >
              <Icon name="calendar" height="25px" />
              <Text fontSize="x-small">matches</Text>
            </Flex>
          </Link>
          <Link to={`wallet/${$userStore?.id}`}>
            <Flex
              direction="column"
              margin="2px 5px 0px 0px"
              alignItems="center"
            >
              <Icon name="dollar" height="25px" />
              <Text fontSize="x-small">wallet</Text>
            </Flex>
          </Link>

          <Link to="leagues">
            <Flex
              direction="column"
              margin="2px 5px 0px 0px"
              alignItems="center"
            >
              <Icon name="users" height="25px" />
              <Text fontSize="x-small">leagues</Text>
            </Flex>
          </Link>

          <Link to="leaderboard">
            <Flex
              direction="column"
              margin="2px 5px 0px 0px"
              alignItems="center"
            >
              <Icon name="trophy" height="25px" />
              <Text fontSize="x-small">rankings</Text>
            </Flex>
          </Link>

          <Flex
            margin="2px 0px 0px 0px"
            alignItems="center"
            onClick={() => {
              drawer.show();
            }}
            direction="column"
          >
            <Icon name="hamburger" height="25px" />
            <Text fontSize="x-small">menu</Text>
          </Flex>
        {:else}
          {#if $location.pathname !== "/"}
            <Link to="/">
              <Flex
                direction="column"
                margin="2px 5px 0px 0px"
                alignItems="center"
              >
                <Icon name="login" height="20px" />
                <Text fontSize="x-small">sign in</Text>
              </Flex>
            </Link>
          {/if}
        {/if}
      </Flex>
    </div>
  </div>
  <sl-drawer
    no-header
    label="Add/Edit Tip"
    class="drawer-custom-size"
    style="--size: 85vw;"
    bind:this={drawer}
  >
    <div class="nav-column">
      <Flex direction="column">
        <Flex direction="column" justify="center" margin="0px 0px 20px 0px">
          <img
            style="width:80%; max-width: 600px;"
            src="/images/odd-tipping-logo.svg"
            alt="text logo"
          />
          <Box maxWidth="600px" width="80%" margin="0px 0px 0px 0px">
            <Flex justify="flex-end">
              <Text fontWeight="600" fontSize="small">
                {sportName}
              </Text>
            </Flex>
          </Box>
          <span
            style="position: absolute; top: -10px; right: -10px"
            on:click={drawer.hide()}
            ><i class="nes-icon close is-small" />
          </span></Flex
        >
        {#each megaMenuItems as item}
          <Link on:click={drawer.hide()} to={item.link}>
            <Flex justify="flex-start">
              <Icon name={item.icon} height="30px" />
              <Text fontSize="20px" fontWeight="bold" margin="0px 0px 20px 10px"
                >{item.name}</Text
              >
            </Flex>
          </Link>
        {/each}
      </Flex>
      <Flex direction="column">
        <!-- <Flex margin="0px 0px 20px 0px">
          <Link on:click={drawer.hide()} to="settings"
            ><Icon
              margin="2px 5px 0px 0px"
              height="15px"
              name="settings"
            /><Text display="inline">settings</Text></Link
          >
        </Flex> -->
        <Flex margin="0px 0px 20px 0px">
          <a href="mailto:ryman@oddtipping.com.au"
            ><Icon margin="2px 5px 0px 0px" height="15px" name="mail" /><Text
              display="inline">contact us</Text
            ></a
          >
        </Flex>
        <Text fontSize="medium">logged in as {$userStore?.name}</Text>
        <VerticalSpacer height="20px" />
        <div>
          <Button
            fontSize="small"
            onClick={() => {
              signOutUser();
              drawer.hide();
              navigate("/");
            }}
          >
            sign out
          </Button>
        </div>
      </Flex>
    </div>
  </sl-drawer>
  <VerticalSpacer />
</nav>

<style>
  nav {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 100;
    padding: 0px 1px;
    display: flex;
  }

  .nav-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  sl-drawer::part(panel) {
    max-width: 450px;
  }
</style>
