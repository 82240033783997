import { db } from '../firebase/';
import {
  query,
  limit,
  getDocs,
  collectionGroup,
  where,
  getCountFromServer, // TODO - upgrade firebase to 9.11 to get access to this feature!!
} from 'firebase/firestore';

export const getMatchUserTipsSample = async (id) => {
  const matchTips = query(
    collectionGroup(db, 'tips'),
    where('matchId', '==', id),
    where('seasonCode', '==', '__fixture_collection__'),
    limit(100) // we just want a sample here
  );
  const tipsTotalSnapshot = await getCountFromServer(matchTips);
  const tipsTotal = tipsTotalSnapshot.data().count;

  if (tipsTotal === 0) return null;
  // NOTE - these queries will need to be added to new collection each season
  // they are not correctly erroring to the client with a suggestion to generate
  // the index – this is an issue with Firebase – trigger the query via getDocs
  // to get the correct error and index creation link
  const homeTeamTips = query(
    collectionGroup(db, 'tips'),
    where('matchId', '==', id),
    where('seasonCode', '==', '__fixture_collection__'),
    where('homeTeam', '==', true),
    limit(100) // we just want a sample here
  );

  const homeTeamTipsTotalSnapshot = await getCountFromServer(homeTeamTips);
  const homeTeamTipsTotal = homeTeamTipsTotalSnapshot.data().count;

  const weightings = {
    hometeam: (homeTeamTipsTotal / tipsTotal) * 100,
    awayteam: ((tipsTotal - homeTeamTipsTotal) / tipsTotal) * 100,
  };

  return weightings;

  // const querySnapshot = await getDocs(matchTips);
  // const data = querySnapshot.docs.map((doc) => doc.data());
  // return data;
};
