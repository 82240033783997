<script>
  import Flex from "./Flex.svelte";
  import Text from "./Text.svelte";
  import { Link } from "svelte-navigator";
  import {fly} from "svelte/transition";
</script>

<div id="footer-wrapper" in:fly="{{ y: 150, duration: 1000 }}">
  <Flex margin="10px" direction="column">
    <Flex justify="space-around">
      <Link to="rules"><Text  fontSize="small">about</Text></Link>
      <a href="mailto:ryman@oddtipping.com.au">
        <Text  fontSize="small">contact</Text>
      </a>
      <Link to="privacy">
        <Text  fontSize="small">privacy</Text></Link
      >
    </Flex>
    <Flex margin="16px 0px" justify="space-around">
      <Text  fontSize="x-small"
        >© copyright Odd Tipping {new Date().getFullYear()}</Text
      >
    </Flex>
  </Flex>
</div>

<style>
  #footer-wrapper {
    width: 100%;
    max-width: 760px;
  }
</style>
