<script>
    export let height = "10px";
</script>

<div style="--height: {height}">

</div>

<style>
    div {
        position: relative;
        height: var(--height);
        width: 100%;
    }
</style>