<script>
  import Button from "./Button.svelte";
  import TextInput from "./TextInput.svelte";
  import Text from "./Text.svelte";
  import { createNewLeague } from "../utils/leagues";
  import { navigate } from "svelte-navigator";
  let leagueName = "";
  export let user;
  import VerticalSpacer from "./VerticalSpacer.svelte";
  import { addToast } from "../stores";

  const startNewLeague = async () => {
    /**
     * 1. Create a new league document
     * 2. Return the document id
     * 3. Add the league doc id to the users 'leagues' array
     * 4. Add the user information to the league doc users array
     */

    try {
      const docId = await createNewLeague(user.id, leagueName);
      addToast({ message: "created! redirecting to league...", type: "info", dismissible:true, timeout:2000 });
      navigate(`/leagues/${docId}`);
    } catch (error) {
      addToast({ message: "no good. try again", type: "error", dismissible:true, timeout:3000 });
    }
  };
</script>

<Text  fontSize="small"
  >To create a new 'league', just enter a (humorous) name below and click 'create league'. Once it's created you can access a league code or link to send to your mates so you can all play Odd Tipping together.</Text
>
<VerticalSpacer />
<!-- <Text  fontSize="small"
    >Give your new league a name (you can change this later).</Text
  > -->
<TextInput placeholder={"your league name..."} maxlength="30" bind:value={leagueName} />
<VerticalSpacer />
<Button fontSize="medium" disabled={!leagueName} onClick={startNewLeague}
  >create league</Button
>

<style></style>
