<script>
  import Icon from './Icon.svelte';
  export let onClick = () => 'This button needs an onClick!';
  export let disabled = false;
  export let primary = false;
  export let fontSize = 'large';
  export let iconRight = '';
  export let height = '100%';

  //---------------

  export let padding = '5px 5px';
  export let margin = '0px';
  export let dropShadow = 'md'; //"drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))";
  export let backgroundColor = '#fff';
  export let borderColor = 'darkGrey';
  export let dropShadowColor = 'grey';
  let dropColor;

  function escapeCSS(str) {
    return str.replace(
      /[!\s"#\$%&'()*+,\-.\/:;<=>?@\[\]\\^{|}~]/g,
      function (c) {
        return '\\' + c.charCodeAt(0).toString(16) + ' ';
      }
    );
  }

  if (primary) {
    borderColor = 'darkGrey';
    dropShadowColor = 'grey';
    backgroundColor = 'rgb(254,222,71)';
  }

  // console.log('disabled', disabled);

  // if (disabled) {
  //   dropShadow = 'null';
  //   borderColor = 'grey';
  // }

  switch (borderColor) {
    case 'yellow':
      borderColor = 'rgb(254,222,71)';
      dropColor = 'rgba(254,222,71,0.5)';
      break;
    case 'red':
      borderColor = 'rgb(235,87,87)';
      break;
    case 'green':
      borderColor = 'rgb(149,214,58)';
      break;
    case 'blue':
      borderColor = 'rgb(75,154,231)';
      break;
    case 'grey':
      borderColor = 'rgb(240,240,240)';
      dropColor = 'rgba(0,0,0,0.2)';
      break;
    case 'white':
      borderColor = 'rgb(255,255,255)';
      break;
    case 'darkGrey':
      borderColor = 'rgb(40,40,40)';
      break;
    default:
      borderColor = 'rgb(256,256,256)';
  }

  switch (dropShadowColor) {
    case 'yellow':
      dropColor = 'rgba(254,222,71,0.5)';
      break;
    case 'red':
      dropColor = 'rgba(235,87,87,0.5)';
      break;
    case 'green':
      dropColor = 'rgba(149,214,58,0.5)';
      break;
    case 'blue':
      dropColor = 'rgba(75,154,231,0.5)';
      break;
    case 'grey':
      dropColor = 'rgba(0,0,0,0.2)';
      break;
    case 'white':
      dropColor = 'rgba(0,0,0,0.2)';
      break;
  }

  var escapedSVG = getEscapedSVG(borderColor);

  function getEscapedSVG(borderColor) {
    var escapedSVG = escapeCSS(
      `data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="${borderColor}" /></svg>`
    );
    return escapedSVG;
  };

  if (dropShadow === 'md') dropShadow = `0px 4px 6px ${dropColor}`;

  //---------------

  let filter = false
    ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(97deg) brightness(105%) contrast(104%)"
    : "";
  let classList = ["thick-border"];
  if (primary) classList.push("is-primary");
  $: isDisabled = disabled ? "is-disabled" : "";
  let disabledBorder = getEscapedSVG('rgb(240,240,240)')
  let disabledFontColor = 'rgb(240,240,240)'
</script>

<button
  style="--font-size: {fontSize}; --height: {height}; --padding: {padding}; --background-color: {backgroundColor}; --margin: {margin}; --dropShadow: {dropShadow}; --border-color: url({escapedSVG}); --disabled-border-color: url({disabledBorder}); --disabled-font-color: {disabledFontColor};"
  class={[isDisabled, ...classList].join(" ")}
  on:click={onClick}
  ><slot />{#if iconRight}
    <Icon {filter} margin="0px 0px 0px 10px" name={`${iconRight}`} />
  {/if}
</button>

<style>
  button {
    font-size: var(--font-size);
    height: var(--height);
    font-weight: 600;
    font-family: 'Source Code Pro', monospace;
    border-image-repeat: stretch !important;
  }

  .thick-border, .is-disabled {
  transition: border-image-source 0.5s ease, box-shadow 0.5s ease, color 0.5s ease;
}

  .thick-border {
    border: black solid 2px;
    background-color: var(--background-color);
    border-image-slice: 3;
    border-image-width: 3;
    border-image-repeat: stretch;
    border-image-source: var(--border-color);
    border-image-outset: 2;
    padding: var(--padding);
    margin: var(--margin);
    box-shadow: var(--dropShadow);
  }

  .is-disabled {
    border-image-source: var(--disabled-border-color);
    box-shadow: none;
    color: var(--disabled-font-color);
  }
</style>
